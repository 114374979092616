<template>
  <v-dialog v-model="broadcastDialog" persistent max-width="860">
    <v-card v-if="broadcastRecipients">
      <v-card-title class="headline">
        {{ $t("card.memo-broadcast") }}
      </v-card-title>
      <v-card-text>{{
        $t("label.to-whom-this-memo-will-be-broadcasted")
      }}</v-card-text>
      <form>
        <v-card-text class="pt-0">
          <v-row>
              <v-col
              cols="12"
              sm="12"
              md="12"
              v-show="listDirektur && currentUser.usertype_elemen == 'DIREKTUR'"
              class="pt-0 pb-xs-0"
            >
              <p class="pa-2">{{$t('text.director-level')}}</p>
              <div class="pl-2 avoore-checkbox--container">
                <div
                  class="avoore-checkbox--responsive"
                  v-for="item in listDirektur"
                  :key="item.nik"
                >
                  <label
                    class="avoore-checkbox path"
                    v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  >
                    <input
                      type="checkbox"
                      class="d-inline-flex"
                      v-model="selectedPersons"
                      :value="item.nik"
                      :id="item.nik"
                    />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                      ></path>
                    </svg>
                    <label :for="item.nik" class="checkbox-title d-inline-flex"
                      >{{ item.nama }}
                    </label>
                    <span
                      :for="item.nik"
                      class="checkbox-subtitle"
                      v-show="currentUser.usertype_elemen == 'DIREKTUR'"
                      >{{ item.nama_jabatan }}</span
                    >
                  </label>
                </div>
              </div>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="12"
              class="px-5 mt-4 pt-0"
              v-show="listKabag && currentUser.usertype_elemen == 'DIREKTUR'"
            >
              <label
                class="avoore-checkbox path"
                v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
              >
                <input
                  type="checkbox"
                  class="d-inline-flex"
                  v-model="selectAllKabag"
                  @change="checkAllKabag"
                  id="select-all-kabag"
                />
                <svg viewBox="0 0 21 21">
                  <path
                    d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                  ></path>
                </svg>
                <label
                  for="select-all-kabag"
                  class="checkbox-title d-inline-flex"
                  >{{ $t("label.select-all-division-head") }}</label
                >
              </label>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              v-show="listKabag && currentUser.usertype_elemen == 'DIREKTUR'"
              class="pt-0 pb-xs-0"
            >
              <div class="pl-2 avoore-checkbox--container">
                <div
                  class="avoore-checkbox--responsive"
                  v-for="item in firstColumnKabag"
                  :key="item.nik"
                >
                  <label
                    class="avoore-checkbox path"
                    v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  >
                    <input
                      type="checkbox"
                      class="d-inline-flex"
                      v-model="selectedPersons"
                      :value="item.nik"
                      :id="item.nik"
                    />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                      ></path>
                    </svg>
                    <label :for="item.nik" class="checkbox-title d-inline-flex"
                      >{{ item.nama }}
                    </label>
                    <span
                      :for="item.nik"
                      class="checkbox-subtitle"
                      v-show="currentUser.usertype_elemen == 'DIREKTUR'"
                      >{{ item.nama_jabatan }}</span
                    >
                  </label>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="pt-0">
              <div class="pl-2 avoore-checkbox--container">
                <div
                  class="avoore-checkbox--responsive"
                  v-for="item in secondColumnKabag"
                  :key="item.nik"
                >
                  <label
                    class="avoore-checkbox path"
                    v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  >
                    <input
                      type="checkbox"
                      class="d-inline-flex"
                      v-model="selectedPersons"
                      :value="item.nik"
                      :id="item.nik"
                    />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                      ></path>
                    </svg>
                    <label
                      :for="item.nik"
                      class="checkbox-title d-inline-flex"
                      >{{ item.nama }}</label
                    >
                    <span
                      :for="item.nik"
                      class="checkbox-subtitle"
                      v-show="currentUser.usertype_elemen == 'DIREKTUR'"
                      >{{ item.nama_jabatan }}</span
                    >
                  </label>
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="px-5 mt-4 pt-0"
              v-show="listManager && currentUser.usertype_elemen == 'DIREKTUR'"
            >
              <label
                class="avoore-checkbox path"
                v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
              >
                <input
                  type="checkbox"
                  class="d-inline-flex"
                  v-model="selectAllManager"
                  @change="checkAllManager"
                  id="select-all-manager"
                />
                <svg viewBox="0 0 21 21">
                  <path
                    d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                  ></path>
                </svg>
                <label
                  for="select-all-manager"
                  class="checkbox-title d-inline-flex"
                  >{{ $t("label.select-all-general-manager") }}</label
                >
              </label>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              v-show="listManager && currentUser.usertype_elemen == 'DIREKTUR'"
              class="pt-0 pb-xs-0"
            >
              <div class="pl-2 avoore-checkbox--container">
                <div
                  class="avoore-checkbox--responsive"
                  v-for="item in firstColumnManager"
                  :key="item.nik"
                >
                  <label
                    class="avoore-checkbox path"
                    v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  >
                    <input
                      type="checkbox"
                      class="d-inline-flex"
                      v-model="selectedPersons"
                      :value="item.nik"
                      :id="item.nik"
                    />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                      ></path>
                    </svg>
                    <label :for="item.nik" class="checkbox-title d-inline-flex"
                      >{{ item.nama }}
                    </label>
                    <span
                      :for="item.nik"
                      class="checkbox-subtitle"
                      v-show="currentUser.usertype_elemen == 'DIREKTUR'"
                      >{{ item.nama_jabatan }}</span
                    >
                  </label>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="pt-0">
              <div class="pl-2 avoore-checkbox--container">
                <div
                  class="avoore-checkbox--responsive"
                  v-for="item in secondColumnManager"
                  :key="item.nik"
                >
                  <label
                    class="avoore-checkbox path"
                    v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  >
                    <input
                      type="checkbox"
                      class="d-inline-flex"
                      v-model="selectedPersons"
                      :value="item.nik"
                      :id="item.nik"
                    />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                      ></path>
                    </svg>
                    <label
                      :for="item.nik"
                      class="checkbox-title d-inline-flex"
                      >{{ item.nama }}</label
                    >
                    <span
                      :for="item.nik"
                      class="checkbox-subtitle"
                      v-show="currentUser.usertype_elemen == 'DIREKTUR'"
                      >{{ item.nama_jabatan }}</span
                    >
                  </label>
                </div>
              </div>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="12"
              class="px-5 mt-4 pt-0"
              v-show="listKasubag && currentUser.usertype_elemen !== 'DIREKTUR'"
            >
              <label
                class="avoore-checkbox path"
                v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
              >
                <input
                  type="checkbox"
                  class="d-inline-flex"
                  v-model="selectAllKasub"
                  @change="checkAllKasubag"
                  id="select-all-kasub"
                />
                <svg viewBox="0 0 21 21">
                  <path
                    d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                  ></path>
                </svg>
                <label
                  for="select-all-kasub"
                  class="checkbox-title d-inline-flex"
                  >{{ $t("label.select-all-subdivision-head") }}</label
                >
              </label>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              v-show="listKasubag"
              class="pt-0 pb-xs-0"
            >
              <div class="pl-2 avoore-checkbox--container">
                <div
                  class="avoore-checkbox--responsive"
                  v-for="item in firstColumnKasubag"
                  :key="item.nik"
                >
                  <label
                    class="avoore-checkbox path"
                    v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  >
                    <input
                      type="checkbox"
                      class="d-inline-flex"
                      v-model="selectedPersons"
                      :value="item.nik"
                      :id="item.nik"
                    />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                      ></path>
                    </svg>
                    <label
                      :for="item.nik"
                      class="checkbox-title d-inline-flex"
                      >{{ item.nama }}</label
                    >
                  </label>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="pt-0">
              <div class="pl-2 avoore-checkbox--container">
                <div
                  class="avoore-checkbox--responsive"
                  v-for="item in secondColumnKasubag"
                  :key="item.nik"
                >
                  <label
                    class="avoore-checkbox path"
                    v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  >
                    <input
                      type="checkbox"
                      class="d-inline-flex"
                      v-model="selectedPersons"
                      :value="item.nik"
                      :id="item.nik"
                    />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                      ></path>
                    </svg>
                    <label
                      :for="item.nik"
                      class="checkbox-title d-inline-flex"
                      >{{ item.nama }}</label
                    >
                  </label>
                </div>
              </div>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="12"
              class="px-5 mt-4 pt-0"
              v-show="listKarpim && currentUser.usertype_elemen !== 'DIREKTUR'"
            >
              <label
                class="avoore-checkbox path"
                v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
              >
                <input
                  type="checkbox"
                  class="d-inline-flex"
                  v-model="selectAllAsisten"
                  @change="checkAllAsisten"
                  id="select-all-asisten"
                />
                <svg viewBox="0 0 21 21">
                  <path
                    d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                  ></path>
                </svg>
                <label
                  for="select-all-asisten"
                  class="checkbox-title d-inline-flex"
                  >{{ $t("label.select-all-assistant") }}</label
                >
              </label>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="6"
              v-show="listKarpim"
              class="pt-0 pb-xs-0"
            >
              <div class="pl-2 avoore-checkbox--container">
                <div
                  class="avoore-checkbox--responsive"
                  v-for="item in firstColumnKarpim"
                  :key="item.nik"
                >
                  <label
                    class="avoore-checkbox path"
                    v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  >
                    <input
                      type="checkbox"
                      class="d-inline-flex"
                      v-model="selectedPersons"
                      :value="item.nik"
                      :id="item.nik"
                    />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                      ></path>
                    </svg>
                    <label
                      :for="item.nik"
                      class="checkbox-title d-inline-flex"
                      >{{ item.nama }}</label
                    >
                  </label>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="pt-0">
              <div class="pl-2 avoore-checkbox--container">
                <div
                  class="avoore-checkbox--responsive"
                  v-for="item in secondColumnKarpim"
                  :key="item.nik"
                >
                  <label
                    class="avoore-checkbox path"
                    v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  >
                    <input
                      type="checkbox"
                      class="d-inline-flex"
                      v-model="selectedPersons"
                      :value="item.nik"
                      :id="item.nik"
                    />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                      ></path>
                    </svg>
                    <label
                      :for="item.nik"
                      class="checkbox-title d-inline-flex"
                      >{{ item.nama }}</label
                    >
                  </label>
                </div>
              </div>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="12"
              class="px-5 mt-4 pt-0"
              v-show="listKrani && currentUser.usertype_elemen !== 'DIREKTUR'"
            >
              <label
                class="avoore-checkbox path"
                v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
              >
                <input
                  type="checkbox"
                  class="d-inline-flex"
                  v-model="selectAllKrani"
                  @change="checkAllKrani"
                  id="select-all-krani"
                />
                <svg viewBox="0 0 21 21">
                  <path
                    d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                  ></path>
                </svg>
                <label
                  for="select-all-krani"
                  class="checkbox-title d-inline-flex"
                  >{{ $t("label.select-all-clerk") }}</label
                >
              </label>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="6"
              v-show="listKrani"
              class="pt-0 pb-xs-0"
            >
              <div class="pl-2 avoore-checkbox--container">
                <div
                  class="avoore-checkbox--responsive"
                  v-for="item in firstColumnKrani"
                  :key="item.nik"
                >
                  <label
                    class="avoore-checkbox path"
                    v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  >
                    <input
                      type="checkbox"
                      class="d-inline-flex"
                      v-model="selectedPersons"
                      :value="item.nik"
                      :id="item.nik"
                    />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                      ></path>
                    </svg>
                    <label
                      :for="item.nik"
                      class="checkbox-title d-inline-flex"
                      >{{ item.nama }}</label
                    >
                  </label>
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="6" class="pt-0">
              <div class="pl-2 avoore-checkbox--container">
                <div
                  class="avoore-checkbox--responsive"
                  v-for="item in secondColumnKrani"
                  :key="item.nik"
                >
                  <label
                    class="avoore-checkbox path"
                    v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                  >
                    <input
                      type="checkbox"
                      class="d-inline-flex"
                      v-model="selectedPersons"
                      :value="item.nik"
                      :id="item.nik"
                    />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                      ></path>
                    </svg>
                    <label
                      :for="item.nik"
                      class="checkbox-title d-inline-flex"
                      >{{ item.nama }}</label
                    >
                  </label>
                </div>
              </div>
            </v-col>
            <v-card-text
              class="pb-0"
              v-if="$v.selectedPersons.$error && !$v.selectedPersons.required"
            >
              <v-alert
                dense
                type="error"
                :icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble top--left"
              >
                {{ $t("error.broadcast-recipient-must-be-choosen") }}
              </v-alert>
            </v-card-text>

            <v-card-text
              class="pb-2"
              v-if="
                $v.chkBroadcastAgree.$error && !$v.chkBroadcastAgree.required
              "
            >
              <v-alert
                dense
                type="error"
                :icon="iconAlert"
                class="modal-alert v-alert--narrow v-alert--bubble bottom--right bottom--right-20-percent"
              >
                {{ $t("error.you-must-agree-to-process") }}
              </v-alert>
            </v-card-text>

            <v-card-text class="d-flex justify-end mr-2 pt-0">
              <label
                class="avoore-checkbox path d-block"
                v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
              >
                <input
                  type="checkbox"
                  class="d-inline-flex"
                  v-model="chkBroadcastAgree"
                  value="oke wak"
                  id="chk-broadcast-agree"
                />
                <svg viewBox="0 0 21 21">
                  <path
                    d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                  ></path>
                </svg>
                <label for="chk-broadcast-agree" class="checkbox-title">{{
                  $t("label.agree-to-process")
                }}</label>
              </label>
            </v-card-text>
          </v-row>
        </v-card-text>
      </form>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>

        <v-btn outlined color="primary darken-1" text @click="submit">
          {{ $t("button.do-broadcast") }}
        </v-btn>
        <v-btn
          outlined
          color="error darken-1"
          text
          @click="broadcastDialog = false"
        >
          {{ $t("button.cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="!broadcastRecipients">
      <v-skeleton-loader
        class="mx-auto"
        max-width="100%"
        type="article, actions"
      ></v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { mdiAlertCircleOutline } from "@mdi/js";
const { required } = require("vuelidate/lib/validators");
// const harusTrue =  (value) => (value != 'false');

export default {
  name: "broadcast-modal",
  props: {
    memoId: String,
    isSecretMemo: Boolean,
  },
  data: () => ({
    iconAlert: mdiAlertCircleOutline,
    selectedPersons: [],
    chkBroadcastAgree: false,
    selectAllDir: false,
    selectAllKabag: false,
    selectAllManager: false,
    selectAllKasub: false,
    selectAllAsisten: false,
    selectAllKrani: false,
  }),
  mixins: [validationMixin],
  validations: {
    selectedPersons: { required },
    chkBroadcastAgree: {
      required(val) {
        return val;
      },
    },
  },
  computed: {
    ...mapState("resources", ["broadcastRecipients"]),
    ...mapState("user", ["currentUser"]),
    broadcastDialog: {
      get() {
        return this.$store.state.inbox.broadcastDialog;
      },
      set(val) {
        this.$store.commit("inbox/SET_BROADCAST_DIALOG", val);
      },
    },
    perColumnKabag() {
      if (!this.listKabag) return 5;
      if (this.isEventOrOdd(this.listKabag.length) == "even") {
        return this.listKabag.length / 2;
      }
      return this.listKabag.length / 2 + 1;
    },
    perColumnManager() {
      if (!this.listManager) return 5;
      if (this.isEventOrOdd(this.listManager.length) == "even") {
        return this.listManager.length / 2;
      }
      return this.listManager.length / 2 + 1;
    },
    perColumnKasubag() {
      if (!this.listKasubag) return 5;
      if (this.isEventOrOdd(this.listKasubag.length) == "even") {
        return this.listKasubag.length / 2;
      }
      return this.listKasubag.length / 2 + 1;
    },
    perColumnKarpim() {
      if (!this.listKarpim) return 5;
      if (this.isEventOrOdd(this.listKarpim.length) == "even") {
        return this.listKarpim.length / 2;
      }
      return this.listKarpim.length / 2 + 1;
    },
    perColumnKrani() {
      if (!this.listKrani) return 5;
      if (this.isEventOrOdd(this.listKrani.length) == "even") {
        return this.listKrani.length / 2;
      }
      return this.listKrani.length / 2 + 1;
    },
    firstColumnKasubag() {
      if (!this.listKasubag) return undefined;
      return this.listKasubag.slice(0, this.perColumnKasubag);
    },
    secondColumnKasubag() {
      if (!this.listKasubag) return undefined;
      return this.listKasubag.slice(
        this.perColumnKasubag,
        this.listKasubag.length
      );
    },
    firstColumnKabag() {
      if (!this.listKabag) return undefined;
      return this.listKabag.slice(0, this.perColumnKabag);
    },
    secondColumnKabag() {
      if (!this.listKabag) return undefined;
      return this.listKabag.slice(this.perColumnKabag, this.listKabag.length);
    },
    firstColumnManager() {
      if (!this.listManager) return undefined;
      return this.listManager.slice(0, this.perColumnManager);
    },
    secondColumnManager() {
      if (!this.listManager) return undefined;
      return this.listManager.slice(
        this.perColumnManager,
        this.listManager.length
      );
    },
    firstColumnKarpim() {
      if (!this.listKarpim) return undefined;
      return this.listKarpim.slice(0, this.perColumnKarpim);
    },
    secondColumnKarpim() {
      if (!this.listKarpim) return undefined;
      return this.listKarpim.slice(
        this.perColumnKarpim,
        this.listKarpim.length
      );
    },
    firstColumnKrani() {
      if (!this.listKrani) return undefined;
      return this.listKrani.slice(0, this.perColumnKrani);
    },
    secondColumnKrani() {
      if (!this.listKrani) return undefined;
      return this.listKrani.slice(this.perColumnKrani, this.listKrani.length);
    },
    listDirektur() {
      if (!this.broadcastRecipients) return [];
      let result = this.broadcastRecipients.filter(function (e) {
        return e.usertype_elemen == "DIREKTUR";
      });
      return result;
    },
    listKabag() {
      if (!this.broadcastRecipients) return [];
      let result = this.broadcastRecipients.filter(function (e) {
        return e.usertype_elemen == "PJP_KANPUS";
      });
      return result;
    },
    listManager() {
      if (!this.broadcastRecipients) return [];
      let result = this.broadcastRecipients.filter(function (e) {
        return e.usertype_elemen == "PJP";
      });
      return result;
    },
    listKasubag() {
      if (!this.broadcastRecipients) return [];
      let result = this.broadcastRecipients.filter(function (e) {
        return e.usertype_elemen == "KASUBAG" || e.usertype_elemen == "KADIS";
      });
      return result;
    },
    listKarpim() {
      if (!this.broadcastRecipients) return [];
      let result = this.broadcastRecipients.filter(function (e) {
        return (
          e.usertype_elemen == "KARPIM" || e.usertype_elemen == "KARPIM_KANPUS"
        );
      });
      return result;
    },
    listKrani() {
      if (!this.broadcastRecipients) return [];
      let result = this.broadcastRecipients.filter(function (e) {
        return e.usertype_elemen == "KRANI" || e.usertype_elemen == "SEKRETARIS_KRANI";
      });
      return result;
    },
    arrDirektur() {
      if (!this.listDirektur) return [];
      return this.listDirektur.map(({ nik }) => nik);
    },
    arrKabag() {
      if (!this.listKabag) return [];
      return this.listKabag.map(({ nik }) => nik);
    },
    arrManager() {
      if (!this.listManager) return [];
      return this.listManager.map(({ nik }) => nik);
    },
    arrKasubag() {
      if (!this.listKasubag) return [];
      return this.listKasubag.map(({ nik }) => nik);
    },
    arrAsisten() {
      if (!this.listKarpim) return [];
      return this.listKarpim.map(({ nik }) => nik);
    },
    arrKrani() {
      if (!this.listKrani) return [];
      return this.listKrani.map(({ nik }) => nik);
    },
  },
  watch: {
    broadcastDialog(val) {
      if (!val) return;
      this.$v.$reset();
      this.selectedPersons = [];
      this.chkBroadcastAgree = false;
      this.setBroadcastRecipients(null);

      this.getDataResource({
        source: "broadcast-recipients",
        bgProcess: true,
      });
    },
    selectedPersons(val) {
      let intersecDirektur = val.filter((element) =>
        this.arrDirektur.includes(element)
      );
      let intersecKabag = val.filter((element) =>
        this.arrKabag.includes(element)
      );
      let intersecManager = val.filter((element) =>
        this.arrManager.includes(element)
      );
      let intersecKasubag = val.filter((element) =>
        this.arrKasubag.includes(element)
      );
      let intersecAsisten = val.filter((element) =>
        this.arrAsisten.includes(element)
      );
      let intersecKrani = val.filter((element) =>
        this.arrKrani.includes(element)
      );

      if (this.compareArray(this.arrDirektur, intersecDirektur) === true) {
        this.selectAllDir = true;
      } else {
        this.selectAllDir = false;
      }
      if (this.compareArray(this.arrKabag, intersecKabag) === true) {
        this.selectAllKabag = true;
      } else {
        this.selectAllKabag = false;
      }
      if (this.compareArray(this.arrManager, intersecManager) === true) {
        this.selectAllManager = true;
      } else {
        this.selectAllManager = false;
      }
      if (this.compareArray(this.arrKasubag, intersecKasubag) === true) {
        this.selectAllKasub = true;
      } else {
        this.selectAllKasub = false;
      }
      if (this.compareArray(this.arrAsisten, intersecAsisten) === true) {
        this.selectAllAsisten = true;
      } else {
        this.selectAllAsisten = false;
      }
      if (this.compareArray(this.arrKrani, intersecKrani) === true) {
        this.selectAllKrani = true;
      } else {
        this.selectAllKrani = false;
      }
    },
  },
  methods: {
    ...mapActions("resources", ["getDataResource", "setBroadcastRecipients"]),
    ...mapActions("inbox", ["submitMyBroadcast"]),
    submit() {
      this.$v.$touch();
      setTimeout(() => {
        if (this.$v.$invalid) return;

        let formData = {
          message: "Broadcast-Memo",
          recipient: this.selectedPersons.toString(),
        };

        this.submitMyBroadcast({
          formData: formData,
          memoId: this.memoId,
        });

        this.$nextTick(() => {
          this.broadcastDialog = false;
          // this.$store.commit("inbox/SET_BROADCAST_DIALOG", val);
        });
      }, 300);
    },
    checkAllKabag(e) {
      this.selectedPersons = this.removeFromArray(
        this.selectedPersons,
        this.arrKabag
      );
      if (e.target.checked) {
        this.selectedPersons = this.addToArray(
          this.selectedPersons,
          this.arrKabag
        );
      }
      this.allKabag = e;
    },
    checkAllManager(e) {
      this.selectedPersons = this.removeFromArray(
        this.selectedPersons,
        this.arrManager
      );
      if (e.target.checked) {
        this.selectedPersons = this.addToArray(
          this.selectedPersons,
          this.arrManager
        );
      }
      this.allKasubag = e;
    },
    checkAllKasubag(e) {
      this.selectedPersons = this.removeFromArray(
        this.selectedPersons,
        this.arrKasubag
      );
      if (e.target.checked) {
        this.selectedPersons = this.addToArray(
          this.selectedPersons,
          this.arrKasubag
        );
      }
      this.allKasubag = e;
    },
    checkAllAsisten(e) {
      this.selectedPersons = this.removeFromArray(
        this.selectedPersons,
        this.arrAsisten
      );
      if (e.target.checked) {
        this.selectedPersons = this.addToArray(
          this.selectedPersons,
          this.arrAsisten
        );
      }
      this.allKarpim = e;
    },
    checkAllKrani(e) {
      this.selectedPersons = this.removeFromArray(
        this.selectedPersons,
        this.arrKrani
      );
      if (e.target.checked) {
        this.selectedPersons = this.addToArray(
          this.selectedPersons,
          this.arrKrani
        );
      }
      this.allKrani = e;
    },
  },
};
</script>